import React from 'react'
import {motion} from 'framer-motion'

import * as Styled from './AppearingText.style'

export default function AppearingText({words, visible = true}) {
  words = words.split('')

  const variants = {
    hidden: {opacity: 0},
    visible: (i) => ({
      opacity: visible ? 1 : 0,
      transition: {
        delay: i * 0.3
      }
    })
  }

  return (
    <Styled.AppearingText>
      {words.map((letter, i) => (
        <motion.span
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={i}
          key={letter + i}
        >
          {letter}
        </motion.span>
      ))}
    </Styled.AppearingText>
  )
}
