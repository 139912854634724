import styled from 'styled-components'
import theme from '../../styles/theme'

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${theme.contentWidth};
  padding: ${theme.paddingAround};
  margin: 0 auto;
  border-top: 1px solid ${theme.colors.light};
  .leftPart {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .rightPart {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-end;
    .socialsContainer {
      display: flex;
      gap: 0.5rem;
      img {
        height: 18px;
      }
    }
  }
`
