import styled from 'styled-components'
import theme from '../../styles/theme'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'

const motionLink = motion(Link)

export const HomeBlocks = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  height: 100%;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    min-height: 450px;
  }
`

export const HomeBlock = styled(motionLink)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  cursor: pointer;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  h2 {
    font-size: 1.8rem;
    text-shadow: ${theme.colors.dark} 2px 3px;
    z-index: 2;
    @media (max-width: ${theme.breakpoints.lg}px) {
      font-size: 1.5rem;
    }
  }
  &.shop {
    grid-area: 1 / 2 / 2 / 3;
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
  &.musique {
    grid-area: 1 / 3 / 3 / 5;
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-area: 4 / 1 / 6 / 2;
    }
  }
  &.contact {
    grid-area: 1 / 6 / 2 / 7;
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-area: 5 / 2 / 6 / 3;
    }
  }
  &.tour {
    grid-area: 2 / 1 / 4 / 2;
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-area: 3 / 1 / 4 / 2;
      margin-left: 25%;
      margin-right: -25%;
    }
  }
  &.textes {
    grid-area: 2 / 5 / 3 / 7;
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-area: 4 / 2 / 5 / 3;
    }
  }
  &.videos {
    grid-area: 3 / 3 / 4 / 5;
    ${'' /* max-width: 75%; */}
    margin-left: -25%;
    margin-right: 25%;
    @media (max-width: ${theme.breakpoints.md}px) {
      max-width: 100%;
      grid-area: 1 / 2 / 2 / 3;
    }
  }
`
