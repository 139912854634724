import styled from 'styled-components'
import theme from '../../styles/theme'

export const AppearingText = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-family: ${theme.fonts.heading};
  font-size: 7rem;
  text-decoration: none;
  @media screen and (max-width: ${theme.breakpoints.vl}px) {
    font-size: 5rem;
  }
  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    font-size: 5rem;
  }
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    font-size: 3rem;
    max-width: 75%;
  }
  font-weight: bold;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-1rem);
  }
`
