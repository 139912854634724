import React from 'react'
import {useContext} from 'react'
import * as Styled from './Shop.style'
import {GatsbyImage} from 'gatsby-plugin-image'
import Context from '../../utils/context/Context'
import {Link} from 'gatsby'
var slugify = require('slugify')

function ProductCard({titre, prix, image, soldOut}) {
  const sluggedTitle = slugify(titre, {
    lower: true,
    strict: true,
    locale: 'fr'
  })
  return (
    <Link
      to={`/product/${sluggedTitle}`}
      className={soldOut ? 'productCard soldOut' : 'productCard'}
    >
      <GatsbyImage image={image} alt={titre} />
      <div className="name">{titre}</div>
      <div className={soldOut ? 'price soldOut' : 'price'}>
        {soldOut ? 'SOLD OUT' : prix + '€'}
      </div>
    </Link>
  )
}

export default function Shop() {
  const {products} = useContext(Context)
  return (
    <Styled.Shop>
      {products?.map((product, i) => {
        return (
          <ProductCard
            titre={product.titre.text}
            prix={product.prix}
            image={product.featuredImage}
            key={i}
            soldOut={product.stock === 0}
          />
        )
      })}
    </Styled.Shop>
  )
}
