import React, {useContext, useEffect, useRef} from 'react'
import * as Styled from '../styles/index.style.jsx'

import Helmet from 'react-helmet'

import HomeBlocks from '../components/HomeBlocks'
import AppearingText from '../components/AppearingText'
import Loop from '../components/Loop'
import Navbar from '../components/Navbar'
import Shop from '../components/Shop'
import Footer from '../components/Footer'
import Context from '../utils/context/Context'

const IndexPage = () => {
  const {setIndexHeight} = useContext(Context)
  const shopRef = useRef(null)
  const indexRef = useRef(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // console.log('Body height changed:', entries[0].target.clientHeight)
      if (indexRef.current) {
        setIndexHeight(indexRef.current.getBoundingClientRect().height)
      }
    })
    resizeObserver.observe(document.body)
  }, [setIndexHeight])

  return (
    <main>
      <Styled.Index ref={indexRef}>
        <Helmet title="Jacques | Home" htmlAttributes={{lang: 'fr'}} />
        <div className="halfContainer">
          <AppearingText words="Jacques" visible />
          <Loop animate marginTop="0rem" />
          {/* <HomeFooter /> */}
        </div>
        <div className="halfContainer">
          <HomeBlocks />
        </div>
      </Styled.Index>
      <div ref={shopRef}>
        <Navbar />
        <Shop />
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
