import theme from './theme'
import styled from 'styled-components'

export const Index = styled.main`
  ${'' /* max-width: ${theme.contentWidth}; */}
  padding: ${theme.paddingAround};
  margin: 0 auto;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.paddingAround};
  align-items: stretch;
  justify-content: flex-start;
  height: 100vh;
  box-sizing: border-box;
  @media (max-width: ${theme.breakpoints.md}px) {
    ${'' /* flex-direction: column-reverse; */}
    height: 701px;
  }
  .halfContainer {
    width: 100%;
    height: 100%;
    ${'' /* padding: 0 ${theme.paddingAround}; */}
    @media (max-width: ${theme.breakpoints.md}px) {
      width: auto;
    }
    &:first-child {
      display: flex;
      justify-content: space-between;
      max-height: 100px;
    }
    ${
      '' /* &:not(:first-child) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid ${theme.colors.light};
      @media (max-width: ${theme.breakpoints.md}px) {
        border-left: none;
      }
    } */
    }
  }
`
