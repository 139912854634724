import styled from 'styled-components'
import theme from '../../styles/theme'

export const Shop = styled.div`
  display: grid;
  grid-gap: 2rem;
  max-width: ${theme.contentWidth};
  padding: ${theme.paddingAround};
  margin: 0 auto;
  margin-bottom: 4rem;
  min-height: 0;
  grid-template-columns: repeat(4, 1fr);
  background-color: ${theme.colors.dark};
  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: ${theme.breakpoints.mm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .productCard {
    cursor: pointer;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      max-width: 80%;
      margin-left: 10%;
    }
    &.soldOut {
      opacity: 1;
    }
    .name {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    .price {
      &.soldOut {
      }
    }
  }
`
