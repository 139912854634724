import styled from 'styled-components'
import theme from '../../styles/theme'
import {motion} from 'framer-motion'

export const Loop = styled(motion.svg)`
  height: 200px;
  max-width: 300px;
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    ${'' /* position: absolute; */}
    ${'' /* top: calc(${theme.paddingAround} + 70px); */}
    ${'' /* left: calc(${theme.paddingAround} + 27%); */}
    ${'' /* height: 50px !important; */}
    ${'' /* margin: 2rem 0; */}
    ${'' /* transform: translateX(-60px); */}
  }
  @media screen and (max-height: 750px) and (min-width: ${theme.breakpoints
      .md}px) {
    margin: 1rem 0 !important;
  }
  @media screen and (max-height: 500px) and (min-width: ${theme.breakpoints
      .md}px) {
    margin: 0 0 !important;
  }
`
