import React from 'react'
import * as Styled from './Footer.style'
import {Link} from 'gatsby'

import facebook from '../../images/icons/facebook.svg'
import youtube from '../../images/icons/youtube.svg'
import spotify from '../../images/icons/spotify.svg'
import instagram from '../../images/icons/instagram.svg'

export default function Footer() {
  return (
    <Styled.Footer>
      <div className="leftPart">
        <Link to="/politique-de-confidentialité">
          Politique de confidentialité
        </Link>
        <Link to="/termes-et-conditions">Termes et conditions</Link>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="rightPart">
        <div className="socialsContainer">
          <a
            href="https://www.instagram.com/jacques_vortex/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC05Om8nHItHP3xi4N8g1eMQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="youtube" />
          </a>
          <a
            href="https://www.facebook.com/jacquesvortex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://open.spotify.com/artist/55i4AnS7E58y41UwE0vvQh?si=UtPT2vzjQg67-ftrgRfbQA&nd=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={spotify} alt="spotify" />
          </a>
        </div>
        <div className="copyright">Jacques © {new Date().getFullYear()}</div>
        <a
          className="credit"
          href="https://pecorino.studio"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pecorino Studio
        </a>
      </div>
    </Styled.Footer>
  )
}
