import React, {useState, useContext, useEffect} from 'react'
import * as Styled from './HomeBlocks.style'
import {motion} from 'framer-motion'

import Context from '../../utils/context/Context'

import warp_1 from '../../images/home/FORMES-01-contact.svg'
import warp_2 from '../../images/home/FORMES-02-textes.svg'
import warp_3 from '../../images/home/FORMES-03-musiques.svg'
import warp_4 from '../../images/home/FORMES-04-tour.svg'
import warp_5 from '../../images/home/FORMES-05-videos.svg'
import warp_6 from '../../images/home/FORMES-06-shop.svg'

import bg1 from '../../images/home/BG/bg1.jpg'
import bg1h from '../../images/home/BG/bg1-h.jpg'
import bg2 from '../../images/home/BG/bg2.jpg'
import bg2h from '../../images/home/BG/bg2-h.jpg'
import bg3 from '../../images/home/BG/bg3.jpg'
import bg3h from '../../images/home/BG/bg3-h.jpg'
import bg4 from '../../images/home/BG/bg4.jpg'
import bg4h from '../../images/home/BG/bg4-h.jpg'
import bg5 from '../../images/home/BG/bg5.jpg'
import bg5h from '../../images/home/BG/bg5-h.jpg'
import bg6 from '../../images/home/BG/bg6.jpg'
import bg6h from '../../images/home/BG/bg6-h.jpg'

function HomeBlock({
  txt,
  i,
  bRad = '0px',
  style = null,
  bg,
  bbg,
  bbgh,
  rotate = 0,
  scrollShop,
  disabled
}) {
  const [isHovered, setIsHovered] = useState(false)
  const [text, setText] = useState(txt.toUpperCase())
  const {setShopClicked} = useContext(Context)

  useEffect(() => {
    if (isHovered && disabled) {
      setText('EN CONSTRUCTION')
    } else if (disabled) {
      setText(txt.toUpperCase())
    }
  }, [isHovered])

  return (
    <Styled.HomeBlock
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{delay: 1.5 + i * 0.3}}
      className={txt.toLowerCase()}
      style={{style, borderRadius: bRad}}
      $rotate={rotate}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e) => {
        if (scrollShop) e.preventDefault()
        if (disabled) e.preventDefault()
        if (scrollShop) {
          setShopClicked(true)
          e.preventDefault()
        }
      }}
      to={!disabled ? txt.toLowerCase() : '#'}
    >
      <motion.img
        initial={{opacity: 0}}
        animate={{
          opacity: isHovered ? 1 : 0
        }}
        transition={{
          duration: 0.5,
          animation: 'easeInOut'
        }}
        src={bbgh}
        alt="bgh"
      />
      <motion.img
        initial={{opacity: 1}}
        animate={{
          opacity: isHovered ? 0 : 1
        }}
        transition={{
          duration: 0.5,
          animation: 'easeInOut'
        }}
        src={bbg}
        alt="bg"
      />
      <motion.img
        initial={{transform: 'scale(1.6) rotate(-5deg)'}}
        animate={{
          transform: isHovered
            ? `scale(1.15) rotate(${rotate}deg)`
            : 'scale(1.01) rotate(0deg)'
        }}
        transition={{
          duration: isHovered ? 1 : 4
        }}
        src={bg}
        alt="warp"
      />
      <h2>{text}</h2>
    </Styled.HomeBlock>
  )
}

export default function HomeBlocks({setIsShop}) {
  return (
    <Styled.HomeBlocks>
      <HomeBlock
        txt="videos"
        i={1}
        bg={warp_5}
        bbg={bg1}
        bbgh={bg1h}
        rotate="-5"
      />
      <HomeBlock txt="tour" i={2} bg={warp_4} bbg={bg2} bbgh={bg2h} />
      <HomeBlock
        txt="shop"
        i={3}
        bg={warp_6}
        bbg={bg3}
        bbgh={bg3h}
        rotate="3"
        scrollShop
      />
      <HomeBlock
        txt="textes"
        i={4}
        bg={warp_2}
        bbg={bg4}
        bbgh={bg4h}
        disabled
      />
      <HomeBlock
        txt="musique"
        i={5}
        bg={warp_3}
        bbg={bg5}
        bbgh={bg5h}
        rotate="-5"
      />
      <HomeBlock
        txt="contact"
        i={6}
        bg={warp_1}
        bbg={bg6}
        bbgh={bg6h}
        style={null}
        rotate="-5"
      />
    </Styled.HomeBlocks>
  )
}
